import React, {useEffect, useState} from 'react';
import Document from "../../../models/documentModel";
import {createErrorMessage} from "../../../util/utils";
import {ResponseData} from '@dvrd/fetch';
import {showDialog} from '@dvrd/dvr-controls';
import {DocumentType} from "../../../util/interfaces";
import VacanciesPage from "./vacanciesPage";

const dummyItems: Array<Document> = [
    new Document({file_name: 'Vacature groep 3-4'})
];
const useDummy = true;

export default function VacanciesController() {
    const [vacancies, setVacancies] = useState<Array<Document>>([]);
    const [loading, setLoading] = useState(true);

    function onDownloadVacancy(vacancy: Document) {
        return function() {
            vacancy.download((_: Document, success: boolean, data: ResponseData) => {
                if (!success)
                    showDialog(createErrorMessage(data.message ?? 'Het downloaden van de vacature is niet gelukt.'),
                        'Download mislukt');
            })
        }
    }

    function loadVacancies() {
        if (useDummy) {
            window.setTimeout(() => {
                setVacancies(dummyItems);
                setLoading(false);
            }, 3000);
        } else {
            Document.getAll({active: true, document_type: DocumentType.VACANCY},
                (vacancies: Array<Document>, success: boolean, data: ResponseData) => {
                    setLoading(false);
                    if (success) setVacancies(vacancies);
                    // TODO Report else
                })
        }
    }

    useEffect(() => {
        loadVacancies();
    }, []);

    return (
        <VacanciesPage onDownloadVacancy={onDownloadVacancy} vacancies={vacancies} loading={loading}/>
    )
}