import React from 'react';

export default function ThinkTankPage() {
    return (
        <div className='default-page think-tank-page'>
            <h1 className='page-title'>LeerlingenDenkTank</h1>
            <section className='section'>
                <h2 className='section-title'>Elke mening telt</h2>
                <p className='section-par'>
                    Ook de leerlingen hebben een stem op onze school. Sinds augustus 2015 heeft onze school een
                    LeerlingenDenkTank. Elke maand komen zij, onder leiding van een leraar en directie,
                    bij elkaar.<br/><br/>
                    <label className='par-title'>Gespreksonderwerpen</label>
                    Tijdens dit overleg komen er allerlei thema’s aan bod waar ze over mee mogen denken. Denk hierbij
                    aan tussenschoolse opvang, schoolactiviteiten en ICT. Ook komen ze vaak met tips en advies die wij
                    op school dankbaar gebruiken om zaken te versterken of te veranderen.
                </p>
            </section>
        </div>
    );
}

ThinkTankPage.displayName = 'ThinkTankPage';