import React from 'react';

export default function ParentsRoomPage() {
    return (
        <div className='default-page parent-room-page'>
            <h1 className='page-title'>Ouderkamer</h1>
            <section className='section'>
                <h2 className='section-title'>Ontmoetingsplaats voor ouders</h2>
                <p className='section-par'>
                    De ouderkamer is een ontmoetingsplaats waar alle ouders elkaar kunnen treffen. Waar contacten
                    ontstaan tussen zowel ouders en school, tussen ouders onderling en tussen ouders, school en externe
                    partners in leren. De ouderkamer wordt ook gebruikt voor cursussen en voorlichtingen over opvoeding,
                    onderwijs & welzijn en informatie over de school.<br/><br/>
                    <label className='par-title'>Contact tussen ouders en school</label>
                    Wij vinden het belangrijk dat onze school en de ouders goed op elkaar aansluiten. In de ouderkamer
                    ontstaat het contact tussen ouders en leerkrachten op een vanzelfsprekende manier. Dat is zowel fijn
                    voor de ouders als voor de kinderen. Kinderen doen beter hun best als hun ouders bij de school
                    betrokken zijn en een beeld hebben wat daar gebeurt. Ouders zijn daarom onmisbaar bij onze missie om
                    van elk kind een winnaar te maken.<br/><br/>
                    <label className='par-title'>Activiteiten</label>
                    Ook dit schooljaar staat er weer een aantal cursussen/bijeenkomsten gepland. In onze nieuwsbrief ‘De
                    Vogelvlucht’ houden we u op de hoogte wanneer er iets plaatsvindt en wat het thema is.
                </p>
            </section>
        </div>
    );
}

ParentsRoomPage.displayName = 'ParentsRoomPage';