import React, {useEffect, useState} from 'react';
import Document from "../../../models/documentModel";
import {DocumentType} from "../../../util/interfaces";
import {ResponseData} from '@dvrd/fetch';
import { showDialog } from '@dvrd/dvr-controls';
import {REQUEST_WAIT_TIME, USE_DUMMY_DATA} from '../../../util/testUtil';
import {createErrorMessage} from "../../../util/utils";
import DocumentsPage from "./documentsPage";

const dummyDocuments: Array<Document> = [
    new Document({file_name: 'Aanmeldformulier de Vuurvogel 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Overzicht vakanties en vrije dagen 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Aanvraagformulier Vakantie en Verlof 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Bewaarvlucht schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Privacyverklaring Wijzer aan de Amstel', file_extension: 'pdf'}),
    new Document({file_name: 'Aanmeldformulier de Vuurvogel 2023-2024', file_extension: 'pdf'}),
];

export default function DocumentsController() {
    const [documents, setDocuments] = useState<Array<Document>>([]);
    const [loading, setLoading] = useState(true);

    function onDownloadDocument(document: Document) {
        return function() {
            document.download((_: Document, success: boolean, data: ResponseData) => {
                if(!success)
                    showDialog(createErrorMessage(data.message ?? 'Het downloaden van het bestand is niet gelukt.'),
                        'Download mislukt');
            })
        }
    }

    function loadDocuments() {
        if (USE_DUMMY_DATA) {
            window.setTimeout(() => {
                setDocuments(dummyDocuments);
                setLoading(false);
            }, REQUEST_WAIT_TIME);
        } else {
            Document.getAll({active: true, document_type: DocumentType.DOCUMENT},
                (documents: Array<Document>, success: boolean, data: ResponseData) => {
                    setLoading(false);
                    if (success) setDocuments(documents);
                    // TODO Report else
                })
        }
    }

    useEffect(() => {
        loadDocuments();
    }, []);

    return (
        <DocumentsPage onDownloadDocument={onDownloadDocument} documents={documents} loading={loading}/>
    );
}