import React from 'react';

export default function Ambition() {
    return (
        <div className='default-page ambition-page'>
            <h1 className='page-title'>Onze ambities</h1>
            <section className='section'>
                <h2 className='section-title'>Ontwikkelend leren staat bij ons centraal</h2>
                <p className='section-par'>
                    De Vuurvogel is een professionele organisatie waar leren en ontwikkeling voorop staat. Wij
                    garanderen een goede plek voor uw kind, nu en straks in het voortgezet onderwijs. Wij hebben hoge
                    verwachtingen en passen alleen die interventies toe die wetenschappelijk bewezen zijn. Het
                    wetenschappelijk gedachtegoed van prof. J. Hattie, R. Ericson en prof.
                    R. Marzano staat in ons dagelijks werken in school centraal.
                </p>
            </section>
            <section className='section'>
                <h2 className='section-title'>Dit betekent in de praktijk:</h2>
                <p className='section-par'>
                    We hebben hoge verwachtingen, bevorderen eigenaarschap, motiveren onze school tot betere prestaties
                    en weten dat elk kind in staat is om buitengewoon te presteren. En dat dit laatste niet afhankelijk
                    is van talent of IQ.
                </p>
            </section>
        </div>
    );
}

Ambition.displayName = 'Ambition';