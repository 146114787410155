import './style/vacanciesPage.scss';

import React, {MouseEventHandler} from 'react';
import Document from "../../../models/documentModel";
import classNames from 'classnames';

interface Props {
    onDownloadVacancy: (vacancy: Document) => MouseEventHandler;
    vacancies: Array<Document>;
    loading: boolean;
}

export default function VacanciesPage(props: Props) {
    const {onDownloadVacancy, vacancies, loading} = props;

    function renderVacancy(newsletter: Document, index: number) {
        return (
            <label key={index} className={classNames('default-link ul click vacancy', loading && 'loading')}
                   onClick={loading ? undefined : onDownloadVacancy(newsletter)}
                   title='Downloaden'>{newsletter.fileName}</label>
        );
    }

    function renderDummyItems() {
        const dummies: Array<Document> = [
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'})
        ];
        return dummies.map(renderVacancy);
    }

    return (
        <div className='default-page vacancies-page'>
            <h1 className='page-title'>Werken bij</h1>
            <section className='section'>
                <h2 className='section-title'>Vacatures</h2>
                <p className='section-par'>
                    Op dit moment hebben wij een vacature voor een leerkracht in groep 3. Als je belangstelling hebt,
                    bespreken we samen wat het beste past.
                </p>
                <div className='vacancies-container'>
                    {loading ? renderDummyItems() : vacancies.map(renderVacancy)}
                </div>
                <p className='section-par'>
                    De Vuurvogel maakt onderdeel uit van de onderwijsstichting Wijzer aan de Amstel. De zes aangesloten
                    basisscholen in Uithoorn en De Kwakel zijn verschillend van karakter en omvang, maar kennen wel een
                    sterke verbondenheid. Voor medewerkers biedt dit ruimte om van elkaar te leren en verschillende
                    ervaringen op te doen. Door de kleinschaligheid van de stichting – 2.000 leerlingen en 200
                    medewerkers – voelt iedereen zich gezien. De scholen kenmerken zich door een professionele en warme
                    sfeer. Kijk op <a href='https://werkenbijwijzeraandeamstel.nl' className='default-link'
                                      target='_blank'>www.werkenbijwijzeraandeamstel.nl</a> voor alle vacatures.
                </p>
            </section>
            <section className='section'>
                <h2 className='section-title'>Samen een team</h2>
                <p className='section-par'>
                    Op De Vuurvogel doen wij elke dag onze uiterste best om vanuit vakmanschap, lef en bevlogenheid het
                    verschil te maken voor onze leerlingen. Omdat we een overzichtelijke school zijn, kennen wij elkaar:
                    Leerlingen, ouders en collega’s. Iedereen voelt zich bij ons gezien en gehoord. Ben je lid van ons
                    team, dan ervaar je dat ook. Wij hebben oog voor ieders specifieke talenten en vinden het belangrijk
                    dat deze zo goed mogelijk tot hun recht komen. Wij willen elk kind dat aan onze zorg is toevertrouwd
                    zich een winnaar laten voelen. Dat is onze missie.
                </p>
            </section>
        </div>
    )
}