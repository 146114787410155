import {useEffect, useState} from 'react';

export default function useIsFirstRender() {
    const [isFirstRender, setIsFirstRender] = useState(false);

    useEffect(() => {
        setIsFirstRender(true)
    }, []);

    return isFirstRender;
};

useIsFirstRender.displayName = 'useIsFirstRender';