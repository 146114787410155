import './style/home.scss';

import React from 'react';
import {CoreItems} from '../../../util/siteData';
import DrawnLogo from '../../misc/drawnLogo';

export default function Home() {
    function renderCore() {
        return (
            <div className='core-container'>
                {CoreItems.map(renderCoreItem)}
            </div>
        );
    }

    function renderCoreItem(item: [string, string], index: number) {
        return (
            <div key={index} className='core-item'>
                <h2 className='core-title'>{item[0]}</h2>
                <p className='core-content'>{item[1]}</p>
            </div>
        );
    }

    function renderVideo() {
        return (
            <div className='video-container'>
                <h2 className='mt'>Bekijk deze video om te zien hoe wij ons motto ‘Elk kind is een winnaar’ in de
                    praktijk brengen.</h2>
                <div className='yt-container'>
                    <iframe width='560' height='315' src='https://www.youtube.com/embed/SAp5ZG_d7mA?si=k6mBo96SOJ8xkYz3&rel=0'
                            title='YouTube video player' frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin' allowFullScreen/>
                </div>
            </div>
        );
    }

    return (
        <div className='default-page home'>
            <h1 className='page-title'>Elk kind is een winnaar</h1>
            <p className='page-par'>Dit is waar we bij de Vuurvogel van overtuigd zijn. Elk kind heeft zijn eigen unieke
                kwaliteiten die verder tot ontwikkeling moeten komen. Het motto ‘Elk kind is een winnaar’ vormt onze
                drijfveer. Elke dag opnieuw.<br/><br/>
                DE VUURVOGEL IS OP ZOEK NAAR LEERKRACHTEN VOOR VERSCHILLENDE GROEPEN.<br/>
                Heb jij affiniteit met lesgeven aan leerlingen uit diverse culturen? En overweeg je een
                overstap?<br/><br/>
                DAN KOMEN WIJ GRAAG MET JOU IN CONTACT!<br/>
                Directeur Dirk Gerrits ziet je telefoontje of mail graag tegemoet.<br/>
                T 0297 521 755 of E dirk@devuurvogel-uithoorn.nl<br/><br/>
                Kijk voor de vacatures onder het tabje 'WERKEN BIJ'
            </p>
            <h1 className='page-title mt flex'><DrawnLogo/> Onze visie</h1>
            <p className='page-par'>Wij geloven in het potentieel van elk kind. Daarom zijn we dagelijks vanuit
                vakmanschap, lef en bevlogenheid bezig om voor onze leerlingen het verschil te maken. Dit doen we door
                op een creatieve wijze onze lessen vorm te geven zodat we de interesse van onze leerlingen wekken. En
                met oog voor het individu.<br/>
                Onze mooiste beloning is als wij verborgen talenten kunnen laten ontluiken.<br/><br/>
                Onze kernwaarden zijn dan ook: <span className='bold'>aandacht</span>, <span
                    className='bold'>bevlogen</span>, <span className='bold'>eigenheid</span> en <span
                    className='bold'>vertrouwen</span>.
            </p>
            {renderCore()}
            {renderVideo()}
        </div>
    );
}

Home.displayName = 'Home';