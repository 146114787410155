import './style/languageToggle.scss';

import React, {useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import {Language} from "../../util/interfaces";
import classNames from 'classnames';

export default function LanguageToggle() {
    const context = useContext(AppContext);

    function onClickLanguage(language: Language) {
        return function() {
            if (language === context.language) return;
            context.onChangeContext({language});
            saveInStorage(language);
        }
    }

    function loadFromStorage() {
        if (!localStorage) return;
        const language: Language | null = localStorage.getItem('language') as Language | null;
        if (!language) return;
        context.onChangeContext({language});
    }

    function saveInStorage(language: Language) {
        if (!localStorage) return;
        localStorage.setItem('language', language);
    }

    function renderLanguage(language: Language) {
        const active = language === context.language;
        const title = language === Language.NL ? 'Nederlands' : 'English';
        return (
            <div className={classNames('language', active && 'active')} onClick={onClickLanguage(language)}>
                <span className={classNames('fi', `fi-${language.toLowerCase()}`)}
                      title={title}/>
            </div>
        )
    }

    useEffect(() => {
        loadFromStorage();
    }, []);

    return (
        <div className='language-toggle'>
            {renderLanguage(Language.NL)}
            <div className='sep'/>
            {renderLanguage(Language.GB)}
        </div>
    )
}