import './style/about.scss';

import {DvrdButton, voidFunction} from '@dvrd/dvr-controls';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {TEAM_ROUTE} from '../../../util/constants';

export default function About() {
    return (
        <div className='default-page about-page'>
            <h1 className='page-title'>Over ons</h1>
            <section className='section'>
                <h2 className='section-title'>Ons motto: 'Elk kind is een winnaar'</h2>
                <p className='section-par'>
                    De Vuurvogel is een eigentijdse school, met een interconfessionele
                    identiteit, gelegen in de Uithoornse wijk Europarei. Onze leerlingen komen elke dag met plezier naar
                    school. Ouders zijn betrokken bij de school. Ons motto is ‘Elk kind is een winnaar’. Hiermee drukken
                    we uit dat wij geloven in het potentieel van elk kind.
                </p>
            </section>
            <section className='section'>
                <h2 className='section-title'>Hoe doen we dat?</h2>
                <p className='section-par'>
                    <ul className='item-list'>
                        <li>Uitgangspunt is dat we door creatieve leervormen de interesse van elke leerling wekken zodat
                            ze plezier in leren hebben.
                        </li>
                        <li>Wij vinden goede taal- en rekenresultaten belangrijk om een sterke plek in het voortgezet
                            onderwijs te kunnen garanderen.</li>
                        <li>Wij zijn een ‘Leren Zichtbaar Maken- school’: dit betekent dat we inzicht geven in de
                            leerdoelen, gericht zijn op een sterke instructie, doen wat echt werkt (Marzano) en hoge
                            verwachtingen hebben van elke leerling.
                        </li>
                        <li>Bij ons leren we samen, maar houden daarbij rekening met het eigen niveau.</li>
                        <li>We werken aan een uitgebreide woordenschat en goed (begrijpend) leren lezen zodat leerlingen
                            straks goed kunnen doorstromen naar het voortgezet onderwijs.
                        </li>
                        <li>Wij geven vanaf groep 1 Engels zodat het de leerlingen voorbereidt op de
                            internationalisering in onze maatschappij.
                        </li>
                        <li>Wij zijn ‘De Vreedzame School’: we leren onze leerlingen (burgerschaps)vaardigheden aan
                            zodat ze worden gestimuleerd om kritisch te denken, te luisteren naar andere meningen,
                            respect voor elkaar te hebben en eigenwaarde te stimuleren.
                        </li>
                        <li>We leren onze leerlingen meer over de wereld om hen heen. Dit doen we met behulp van zowel
                            technologie, wereldoriëntatie, creatieve vakken als wetenschap.
                        </li>
                        <li>
                            Voor meer- en hoogbegaafden hebben we specifieke programma’s.
                        </li>
                    </ul>
                    <NavLink to={TEAM_ROUTE}>
                        <DvrdButton className='btn-team' label='Het team' onClick={voidFunction}/>
                    </NavLink>
                </p>
            </section>
        </div>
    );
}

About.displayName = 'About';