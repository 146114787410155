import './style/agendaPage.scss';

import React from 'react';
import CalendarItem from '../../../models/calendarItemModel';
import classNames from 'classnames';
import IDate from '@dvrd/idate';

interface Props {
    items: Array<CalendarItem>;
    loading: boolean;
}

const now = IDate.now();

export default function AgendaPage(props: Props) {
    const {items, loading} = props;

    function renderItem(item: CalendarItem, index: number) {
        return (
            <div key={index} className={classNames('calendar-item', loading && 'loading')}>
                <label>{item.period}</label>
                <label>{item.label}</label>
            </div>
        );
    }

    function renderDummyItems() {
        const items: Array<CalendarItem> = [
            new CalendarItem({label: 'a'}),
            new CalendarItem({label: 'a'}),
            new CalendarItem({label: 'a'}),
            new CalendarItem({label: 'a'}),
            new CalendarItem({label: 'a'})
        ];
        return items.map(renderItem);
    }

    return (
        <div className='default-page agenda-page'>
            <h1 className='page-title'>Agenda</h1>
            <section className='section'>
                <h2 className='section-title'>Activiteiten {now.clone().subtract(1, 'year').year()} - {now.year()}</h2>
                <div className='calendar-items-container'>
                    {loading ? renderDummyItems() : items.map(renderItem)}
                </div>
            </section>
        </div>
    );
}

AgendaPage.displayName = 'AgendaPage';