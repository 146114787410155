import './style/newslettersPage.scss';

import React, {MouseEventHandler} from 'react';
import Document from '../../../models/documentModel';
import classNames from 'classnames';

interface Props {
    onDownloadNewsletter: (newsletter: Document) => MouseEventHandler;
    newsletters: Array<Document>;
    loading: boolean;
    downloading: boolean;
}

export default function NewslettersPage(props: Props) {
    const {loading, onDownloadNewsletter, newsletters, downloading} = props;

    function renderNewsletter(newsletter: Document, index: number) {
        return (
            <label key={index} className={classNames('default-link ul click newsletter', loading && 'loading')}
                   onClick={loading ? undefined : onDownloadNewsletter(newsletter)}
                   title='Downloaden'>{newsletter.fileName}</label>
        );
    }

    function renderDummyNewsletters() {
        const dummies: Array<Document> = [
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'})
        ];
        return dummies.map(renderNewsletter);
    }

    return (
        <div className='default-page newsletters-page'>
            <h1 className='page-title'>Nieuwsbrieven</h1>
            <section className='section'>
                <h2 className='section-title'>Altijd op de hoogte van het laatste nieuws</h2>
                <p className='section-par'>
                    Wij streven ernaar om wekelijks een nieuwsbrief te versturen naar alle ouders/verzorgers. Dit kan
                    een keer meer of minder zijn maar wij zorgen ervoor dat u altijd op de hoogte bent van het laatste
                    nieuws van De Vuurvogel.<br/><br/>
                </p>
                <div className='newsletters-container'>
                    {loading ? renderDummyNewsletters() : newsletters.map(renderNewsletter)}
                </div>
            </section>
        </div>
    );
}

NewslettersPage.displayName = 'NewslettersPage';