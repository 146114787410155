import React, {useEffect, useState} from 'react';
import CalendarItem from '../../../models/calendarItemModel';
import {CalendarItemType} from '../../../util/interfaces';
import {ResponseData} from '@dvrd/fetch';
import {REQUEST_WAIT_TIME, USE_DUMMY_DATA} from '../../../util/testUtil';
import OpeningHoursPage from './openingHoursPage';

const dummyItems: Array<CalendarItem> = [
    new CalendarItem({label: 'Herfstvakantie', from_date: '2023-10-23', to_date: '2023-10-27'}),
    new CalendarItem({label: 'Kerstvakantie', from_date: '2023-12-25', to_date: '2024-01-05'}),
    new CalendarItem({label: 'Krokusvakantie', from_date: '2024-02-19', to_date: '2024-02-23'}),
    new CalendarItem({label: 'Paasvakantie', from_date: '2024-03-29', to_date: '2024-04-01'}),
    new CalendarItem({
        label: 'Meivakantie', from_date: '2024-04-29', to_date: '2024-05-10',
        comment: '(incl. Koningsdag en Hemelvaart)'
    }),
    new CalendarItem({label: 'Pinksteren', from_date: '2024-05-20'}),
    new CalendarItem({label: 'Zomervakantie', from_date: '2024-07-22', to_date: '2024-08-30'})
];

export default function OpeningHoursController() {
    const [holidayItems, setHolidayItems] = useState<Array<CalendarItem>>([]);
    const [loading, setLoading] = useState(true);

    function loadHolidayItems() {
        if (USE_DUMMY_DATA) {
            window.setTimeout(() => {
                setHolidayItems(dummyItems);
                setLoading(false);
            }, REQUEST_WAIT_TIME);
        } else {
            CalendarItem.getAll({active: true, item_type: CalendarItemType.HOLIDAY},
                (items: Array<CalendarItem>, success: boolean, data: ResponseData) => {
                    setLoading(false);
                    if (success) setHolidayItems(items);
                    // TODO REPORT ELSE
                });
        }
    }

    useEffect(() => {
        loadHolidayItems();
    }, []);

    return (
        <OpeningHoursPage holidayItems={holidayItems} loading={loading}/>
    );
}

OpeningHoursController.displayName = 'OpeningHoursController';