import {showDialog} from '@dvrd/dvr-controls';
import {ResponseData} from '@dvrd/fetch';
import React, {useEffect, useState} from 'react';
import Document from '../../../models/documentModel';
import {DocumentType} from '../../../util/interfaces';
import {REQUEST_WAIT_TIME, USE_DUMMY_DATA} from '../../../util/testUtil';
import {createErrorMessage} from '../../../util/utils';
import NewslettersPage from './newslettersPage';

const dummyData: Array<Document> = [
    new Document({file_name: 'Vogelvlucht 13 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 12 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 11 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 10 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 9 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 8 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 7 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 6 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 5 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 4 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 3 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 2 schooljaar 2023-2024', file_extension: 'pdf'}),
    new Document({file_name: 'Vogelvlucht 1 schooljaar 2023-2024', file_extension: 'pdf'})
];

export default function NewslettersController() {
    const [newsletters, setNewsletters] = useState<Array<Document>>([]);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);

    function onDownloadNewsletter(newsletter: Document) {
        return function () {
            setDownloading(true);
            newsletter.download((_: Document, success: boolean, data: ResponseData) => {
                setDownloading(false);
                if (!success) showDialog(
                    createErrorMessage(data.message ?? 'Het downloaden van de nieuwsbrief is niet gelukt'),
                    'Download mislukt');
            });
        };
    }

    function loadDocuments() {
        if (USE_DUMMY_DATA)
            window.setTimeout(() => {
                setNewsletters(dummyData);
                setLoading(false);
            }, REQUEST_WAIT_TIME);
        else
            Document.getAll({document_type: DocumentType.NEWSLETTER, active: true},
                (documents: Array<Document>, success: boolean) => {
                    setLoading(false);
                    if (success) setNewsletters(documents);
                    // Report else
                });
    }

    useEffect(() => {
        loadDocuments();
    }, []);

    return (
        <NewslettersPage onDownloadNewsletter={onDownloadNewsletter} newsletters={newsletters} loading={loading}
                         downloading={downloading}/>
    );
}

NewslettersController.displayName = 'NewslettersController';