import './style/validateTwoFactor.scss';

import React, {KeyboardEventHandler, MouseEventHandler} from 'react';
import {ChangeFunction, DvrdButton, DVRDNumberInput, Loader, WithBackground} from '@dvrd/dvr-controls';

interface Props {
    onChange: ChangeFunction<string>;
    onSubmit: MouseEventHandler & KeyboardEventHandler;
    onClose: MouseEventHandler;
    token: string;
    loading: boolean;
    active: boolean;
}

export default function ValidateTwoFactor(props: Props) {
    const {active, onClose, onChange, token, loading, onSubmit} = props;

    return (
        <WithBackground active={active} onClose={onClose}>
            <div className='validate-two-factor default-popup'>
                <div className='header-container'>
                    <label className='header-title'>Tweestapsverificatie</label>
                </div>
                <div className='body-container'>
                    <Loader active={loading}/>
                    <p>Vul de 6-cijferige code van je Authenticator app in.</p>
                    <DVRDNumberInput value={token} onChange={onChange} label='Code' onEnter={onSubmit} wholeNumbers/>
                </div>
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClose} label='Annuleren' disabled={loading} secondary/>
                    <DvrdButton onClick={onSubmit} label='Oké' disabled={loading}/>
                </div>
            </div>
        </WithBackground>
    )
}