import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export default function useScroller() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0});
    }, [pathname]);
}

useScroller.displayName = useScroller;