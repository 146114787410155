import './style/documentsPage.scss';

import React, {MouseEventHandler} from 'react';
import Document from "../../../models/documentModel";
import classNames from 'classnames';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import { AwesomeIcon } from '@dvrd/dvr-controls';

interface Props {
    onDownloadDocument: (document: Document) => MouseEventHandler;
    documents: Array<Document>;
    loading: boolean;
}

function getIconName(extension: string): IconName | null {
    if (extension.endsWith('pdf')) return 'file-pdf';
    else if (extension.endsWith('xlsx') || extension.endsWith('xls')) return 'file-excel';
    else if (extension.endsWith('docx')) return 'file-word';
    return null;
}

export default function DocumentsPage(props: Props) {
    const {loading, documents, onDownloadDocument} = props;

    function renderDocument(document: Document, index: number) {
        const iconName = getIconName(document.fileExtension);
        return (
            <div className={classNames('document click', loading && 'loading', !!iconName && 'with-icon')}
                 onClick={loading ? undefined : onDownloadDocument(document)} title='Downloaden'>
                {!!iconName && <AwesomeIcon name={iconName} className='document-icon'/>}
                <label key={index} className={classNames('default-link ul click',
                    loading && 'loading')}>{document.fileName}</label>
            </div>
        )
    }

    function renderDummyDocuments() {
        const dummies: Array<Document> = [
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'}),
            new Document({file_name: 'a'})
        ];
        return dummies.map(renderDocument);
    }

    return (
        <div className='default-page documents-page'>
            <h1 className='page-title'>Documenten</h1>
            <section className='section'>
                <h2 className='section-title'>De belangrijkste informatie op een rijtje</h2>
                <p className='section-par'>In onderstaande documenten staat onze belangrijkste informatie.</p>
                <div className='documents-container'>
                    {loading ? renderDummyDocuments() : documents.map(renderDocument)}
                </div>
            </section>
        </div>
    )
}