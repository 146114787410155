import React from 'react';

export default function OvPage() {
    return (
        <div className='default-page ov-page'>
            <h1 className='page-title'>Oudervereniging (OV)</h1>
            <section className='section'>
                <h2 className='section-title'>Wat doet de OV?</h2>
                <p className='section-par'>
                    De oudervereniging (OV) is een vereniging van ouders/verzorgers met kinderen op De Vuurvogel. Iedere
                    ouder/verzorger met een kind dat onderwijs volgt op De Vuurvogel is automatisch lid van de
                    oudervereniging.
                    Deze enthousiaste en gemotiveerde leden zorgen ervoor dat activiteiten als sport, excursies,
                    toernooien, Sinterklaas, Kerst, Pasen, schoolreis, afscheidsavond zo goed en gezellig mogelijk
                    georganiseerd worden voor alle kinderen.<br/><br/>
                    Om al deze activiteiten te kunnen laten slagen, wordt er soms om hulpouders gevraagd. Het helpen en
                    assisteren bij activiteiten is een erg leuke en gezellige bezigheid! Daarnaast wordt er ook een
                    kleine ouderbijdrage gevraagd. Het bedrag wordt ieder jaar vastgesteld tijdens de oudervereniging
                    vergadering.<br/><br/>
                    Wilt u zelf ook actief lid worden van de oudervereniging dan kunt u een mail sturen naar <a
                    className='default-link' href='mailto:ov@devuurvogel-uithoorn.nl'
                    target='_blank'>ov@devuurvogel-uithoorn.nl</a>
                </p>
            </section>
        </div>
    );
}

OvPage.displayName = 'OvPage';