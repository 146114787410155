import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style/header.scss';
import classNames from 'classnames';

import React, {MouseEventHandler, useEffect, useState} from 'react';
import {Carousel} from 'react-responsive-carousel';
import {NavLink} from 'react-router-dom';
import {HeaderItems} from '../../routes';
import {HOME_ROUTE} from '../../util/constants';
import {HeaderItem} from '../../util/interfaces';
import Image from '../controls/image';
import useIsFirstRender from '../misc/firstRender';
import Logo from '../misc/logo';

const CAROUSEL_INTERVAL = 5000;

interface MenuItemProps {
    item: HeaderItem;
    isChild?: boolean;
    onClick?: (item: HeaderItem) => MouseEventHandler;
    onClose?: VoidFunction;
}

function MenuItem(props: MenuItemProps) {
    const {item, isChild, onClick, onClose} = props;
    const {label, className, children, route} = item;
    const [visible, setVisible] = useState(false);

    function onMouseLeave() {
        setVisible(false);
    }

    function onMouseEnter() {
        setVisible(true);
    }

    function onClickItem(item: HeaderItem) {
        return function () {
            if (item.isChild) onClose?.();
            else setVisible(!visible);
        };
    }

    function _onClose() {
        setVisible(false);
    }

    function renderChild(item: HeaderItem, index: number) {
        return <MenuItem key={index} item={item} onClick={onClick} onClose={_onClose} isChild/>;
    }

    return (
        <div className={classNames('header-menu-item', isChild && 'child', className)}
             onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <NavLink to={route} className='header-item-link' onClick={onClickItem({...item, isChild})}>
                {({isActive}) => (
                    <label className={classNames('header-item-label', isActive && 'active')}>{label}</label>
                )}
            </NavLink>
            {!!children && (
                <div className={classNames('header-item-children', visible && 'visible')}>
                    {children.map(renderChild)}
                </div>
            )}
        </div>
    );
}

export default function Header() {
    const firstRender = useIsFirstRender();

    function renderCarousel() {
        const imageNames: Array<[string, string]> = Array(5).fill(['vuurvogel-hero-x1200', 'jpg']);
        return (
            <Carousel autoPlay infiniteLoop showArrows={false} showIndicators={false}
                      showStatus={false} showThumbs={false} interval={CAROUSEL_INTERVAL}>
                {imageNames.map((image: [string, string], index: number) => (
                    <Image key={index} name={image[0]} fallbackExtension={image[1]}/>
                ))}
            </Carousel>
        );
    }

    function renderMenu() {
        return (
            <div className='header-menu'>
                {HeaderItems.map(renderMenuItem)}
            </div>
        );
    }

    function renderMenuItem(item: HeaderItem, index: number) {
        return <MenuItem key={index} item={item}/>;
    }

    // function renderMenuItem(isChild: boolean = false) {
    //     return function (item: HeaderItem, index: number) {
    //         const {label, className, children, route} = item;
    //         return (
    //             <div key={index} className={classNames('header-menu-item', isChild && 'child', className)}>
    //                 <NavLink to={route} className='header-item-link'>
    //                     {({isActive}) => (
    //                         <label className={classNames('header-item-label', isActive && 'active')}>{label}</label>
    //                     )}
    //                 </NavLink>
    //                 {!!children && (
    //                     <div className='header-item-children'>
    //                         {children.map(renderMenuItem(true))}
    //                     </div>
    //                 )}
    //             </div>
    //         );
    //     };
    // }

    function validateChildrenPositions() {
        const containers: NodeListOf<HTMLDivElement> = document.querySelectorAll('div.header-item-children');
        const screenWidth = document.documentElement.clientWidth;
        for (const container of containers) {
            const {right, left} = container.getBoundingClientRect();
            if (right > screenWidth) container.style.left = (left - (right - screenWidth)) + 'px';
        }
    }

    useEffect(() => {
        if (!firstRender) validateChildrenPositions();
    }, [firstRender]);

    return (
        <div className='vuurvogel-header'>
            <div className='hero-container'>
                <NavLink className='logo-container' to={HOME_ROUTE}>
                    <Logo/>
                </NavLink>
                <div className='carousel-container'>
                    {renderCarousel()}
                    <div className='logo-padding'/>
                </div>
            </div>
            {renderMenu()}
        </div>
    );
}

Header.displayName = 'Header';