import React from 'react';

export default function AmstelPage() {
    return (
        <div className='default-page amstel-page'>
            <h1 className='page-title'>Wijzer aan de Amstel</h1>
            <section className='section'>
                <h2 className='section-title'>Samen sterk</h2>
                <p className='section-par'>
                    De Vuurvogel maakt deel uit van de overkoepelende stichting Wijzer aan de Amstel.<br/><br/>
                    Stichting <a className='default-link' href='https://wijzeraandeamstel.nl/' target='_blank'>Wijzer
                    aan de Amstel</a> is op 1 januari 2019 ontstaan uit een fusie tussen 3 stichtingen: Stichting
                    Interconfessioneel Basisonderwijs Uithoorn, Stichting Katholiek onderwijs De Kwakel en Stichting
                    Jenaplanonderwijs Uithoorn. De nieuwe stichting heeft zowel katholieke als interconfessionele
                    scholen. Naast De Vuurvogel zijn er nog vijf andere basisscholen gevestigd in de gemeente Uithoorn.
                </p>
            </section>
            <section className='section'>
                <p className='section-par'>
                    <label className='par-title'>Identiteit</label>
                    De christelijke en katholieke waarden vormen de grondslag van de stichting. Daarnaast staat de
                    stichting respectvol tegenover andere geloofsovertuigingen. Binnen het onderwijs wordt aandacht
                    gegeven aan godsdienstige en levensbeschouwelijke vorming van de leerlingen.
                </p>
            </section>
            <section className='section'>
                <p className='section-par'>
                    <label className='par-title'>Levensbeschouwing</label>
                    Tijdens de levensbeschouwelijke vorming wordt de leerlingen geleerd, aangepast aan hun ontwikkeling,
                    om te gaan met vragen over de zin van het bestaan. Zij leren daarbij wat kenmerkend is voor een
                    levensbeschouwelijke benadering van de werkelijkheid. Tijdens de levensbeschouwelijke vorming komen
                    de levensvragen niet op een abstracte wijze aan de orde, maar worden deze vragen steeds weer
                    verbonden met antwoorden uit een bepaalde traditie en/of geloofsovertuiging. Daarnaast komen ook
                    andere levensbeschouwelijke tradities aan bod.
                </p>
            </section>
            <section className='section'>
                <p className='section-par'>
                    <label className='par-title'>Respect voor elkaar</label>
                    Zo leren de kinderen dat er in de samenleving verschillende godsdienstige en levensbeschouwelijke
                    tradities zijn en ontwikkelen ze respect voor opvattingen en belevingen van anderen.
                </p>
            </section>
        </div>
    );
}

AmstelPage.displayName = 'AmstelPage';