import React, {useState} from 'react';
import {ContactErrors} from '../../../util/interfaces';
import {validateEmail} from '@dvrd/dvr-controls';
import ContactPage from "./contactPage";

export default function ContactController() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState<ContactErrors>({name: null, email: null, subject: null, message: null});
    const [loading, setLoading] = useState(false);

    function onChangeName(value: string) {
        setName(value);
        onResetError('name')();
    }

    function onChangeEmail(value: string) {
        setEmail(value);
        onResetError('email')();
    }

    function onChangeSubject(value: string) {
        setSubject(value);
        onResetError('subject')();
    }

    function onChangeMessage(value: string) {
        setMessage(value);
        onResetError('message')();
    }

    function onResetError(key: keyof ContactErrors) {
        return function() {
            setErrors({...errors, [key]: null});
        }
    }

    function onSubmit() {
        if (!validateValues()) return;
        setLoading(true);
        // TODO
    }

    function validateValues(): boolean {
        const _errors = {...errors};
        let valid: boolean = true;
        if (!name) {
            valid = false;
            _errors.name = 'Vul je naam in';
        }
        if (!email) {
            valid = false;
            _errors.email = 'Vul je e-mailadres in';
        } else if (!validateEmail(email)) {
            valid = false;
            _errors.email = 'Vul een geldig e-mailadres in';
        }
        if (!subject) {
            valid = false;
            _errors.subject = 'Vul het onderwerp in';
        }
        if (!message) {
            valid = false;
            _errors.message = 'Vul je bericht in';
        }
        if (!valid) setErrors(_errors);
        return valid;
    }

    return (
        <ContactPage onChangeName={onChangeName} onChangeEmail={onChangeEmail} onChangeSubject={onChangeSubject}
                     onChangeMessage={onChangeMessage} onResetError={onResetError} onSubmit={onSubmit} name={name}
                     email={email} subject={subject} message={message} errors={errors} loading={loading}/>
    );
}