import React from 'react';

export default function Quality() {
    return (
        <div className='default-page quality-page'>
            <h1 className='page-title'>Ons kwaliteitsniveau</h1>
            <section className='section'>
                <h2 className='section-title'>Aansluiten bij elk individueel kind</h2>
                <p className='section-par'>
                    Op basisschool De Vuurvogel streven we naar onderwijsresultaten passend bij de
                    ontwikkelingsverwachting van het kind. Hierbij is ons doel maximale resultaten en
                    ontwikkelingskansen voor onze kinderen te realiseren.
                </p>
                <p className='section-par'>
                    <label className='par-title'>Gedeelde visie</label>
                    Een breed gedragen visie van het team en de directie vormt het uitgangspunt voor het vormgeven van
                    de koers van de school.
                    Deze visie geeft duidelijkheid wat er van hem of haar wordt verwacht.
                    ‘Samen leren inhoud geven’ is het ontwikkelmodel dat bij onze manier van lesgeven past
                </p>
            </section>
            <section className='section'>
                <h2 className='section-title'>Focus op opbrengst</h2>
                <p className='section-par'>
                    Uit onderzoek blijkt dat scholen zelf belangrijke sleutels in handen hebben om de opbrengsten te
                    verbeteren. Goed gekozen maatregelen op zowel leerling-, groeps- en schoolniveau leveren een
                    belangrijke bijdrage aan het verhogen van de leerlingenprestaties en dus ook aan het verhogen van de
                    schoolprestaties. Leerlingen op opbrengstgerichte scholen boeken dan ook meer vooruitgang dan
                    leerlingen op weinig opbrengstgerichte scholen.
                </p>
            </section>
        </div>
    );
}

Quality.displayName = 'Quality';