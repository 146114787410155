import React from 'react';

export default function GmrPage() {
    return (
        <div className='default-page gmr-page'>
            <h1 className='page-title'>Gemeenschappelijke medezeggenschapsraad (GMR)</h1>
            <section className='section'>
                <h2 className='section-title'>Wat doet de GMR?</h2>
                <p className='section-par'>
                    Naast de MR is er ook een Gemeenschappelijke MedezeggenschapsRaad (GMR). Dit is een
                    schooloverstijgend orgaan, waarin zaken besproken worden die niet alleen met de eigen school te
                    maken hebben, maar ook met andere scholen binnen onze stichting Wijzer aan de Amstel.<br/><br/>
                    <label className='par-title'>Wie zitten er in de GMR?</label>
                    In de GMR zit een vertegenwoordiging van de ouders en leerkrachten van alle scholen van onze
                    stichting.<br/><br/>
                    <label className='par-title'>Bevoegdheden</label>
                    Ook hier gelden advies- en instemmingsbevoegdheden. Het bestuur van onze scholen (stichting Wijzer
                    aan de Amstel) moet voor belangrijke zaken de mening/instemming van de GMR vragen. De GMR (of MR)
                    kan ook ongevraagd het bestuur advies geven. Op deze manier hebben ouders en personeelsleden de
                    mogelijkheid mee te denken over het beleid van ons bestuur.
                </p>
            </section>
        </div>
    );
}

GmrPage.displayName = 'GmrPage';