import './style/teamPage.scss';

import React from 'react';
import Employee from '../../../models/employeeModel';
import classNames from 'classnames';

interface Props {
    team: Array<Employee>;
    loading: boolean;
}

export default function TeamPage(props: Props) {
    const {team, loading} = props;

    function renderEmployee(employee: Employee, index: number) {
        return (
            <div key={index} className={classNames('employee', loading && 'loading')}>
                <label className='employee-name'>{employee.firstname}</label>
                <label className='employee-group'>{employee.group ?? 'Leerkracht'}</label>
                <a className='employee-email' href={`mailto:${employee.email}`} target='_blank'>{employee.email}</a>
            </div>
        );
    }

    function renderDummyEmployees() {
        const employees = [
            new Employee({firstname: 'a', lastname: 'a', group: 'a', email: 'a'}),
            new Employee({firstname: 'a', lastname: 'a', group: 'a', email: 'a'}),
            new Employee({firstname: 'a', lastname: 'a', group: 'a', email: 'a'}),
            new Employee({firstname: 'a', lastname: 'a', group: 'a', email: 'a'}),
            new Employee({firstname: 'a', lastname: 'a', group: 'a', email: 'a'}),
        ];
        return employees.map(renderEmployee);
    }

    return (
        <div className='default-page team-page'>
            <h1 className='page-title'>Ons team</h1>
            <div className='employees-list'>
                {loading ? renderDummyEmployees() : team.map(renderEmployee)}
            </div>
        </div>
    );
}

TeamPage.displayName = 'TeamPage';