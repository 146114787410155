import './style/openingHoursPage.scss';

import React from 'react';
import CalendarItem from '../../../models/calendarItemModel';
import classNames from 'classnames';
import IDate from '@dvrd/idate';

interface Props {
    holidayItems: Array<CalendarItem>;
    loading: boolean;
}

const now = IDate.now();

export default function OpeningHoursPage(props: Props) {
    const {holidayItems, loading} = props;

    function renderItem(item: CalendarItem, index: number) {
        return (
            <div key={index}
                 className={classNames('holiday-item', loading && 'loading')}>
                <label className='item-label'>{item.label}</label>
                <div className='period-container'>
                    <label>{item.extendedPeriod}</label>
                    {!!item.comment && <label>{item.comment}</label>}
                </div>
            </div>
        );
    }

    function renderDummyItems() {
        const items: Array<CalendarItem> = [
            new CalendarItem({label: 'a'}),
            new CalendarItem({label: 'a'}),
            new CalendarItem({label: 'a'}),
            new CalendarItem({label: 'a'}),
            new CalendarItem({label: 'a'})
        ];
        return items.map(renderItem);
    }

    return (
        <div className='default-page opening-hours-page'>
            <h1 className='page-title'>Openingstijden</h1>
            <section className='section'>
                <h2 className='section-title'>Elke dag van 08.00 – 14.00 uur naar school</h2>
                <p className='section-par'>
                    Basisschool De Vuurvogel is schooljaar 2010-2011 als eerste school in Uithoorn met het “vijf gelijke
                    dagen model” gaan werken. De deuren gaan om 08.00 uur open, waardoor voorschoolse opvang niet meer
                    nodig is, de kinderen hebben alle dagen van 08.15 tot 14.00 uur les. De tussenschoolse opvang (grote
                    pauze) wordt door de school verzorgd. Op deze manier wordt effectief gebruik gemaakt van de
                    leertijd.<br/><br/>
                    Wij hebben voor dit model gekozen, op de eerste plaats, vanwege de voordelen voor de kinderen. Maar
                    uiteraard zijn er ook voordelen voor ouders en leerkrachten. U kunt dan denken aan:
                    <ul>
                        <li>Een evenwichtige spreiding van de lessen over de dag en de week heeft een positieve invloed
                            op het concentratievermogen van kinderen;
                        </li>
                        <li>Er is sprake van een uitgebalanceerd onderwijsprogramma waar kinderen zich betrokken bij
                            voelen waardoor er rust en ruimte is voor een goede ontwikkeling;
                        </li>
                        <li>De kinderen blijven de hele dag in het schoolritme;</li>
                        <li>De kinderen eten tussen de middag in de eigen groep: dit bevordert ook de sociale relaties
                            tussen de leerlingen en de leerkracht;
                        </li>
                        <li>De kinderen hebben na 14.00 uur voldoende tijd om met vriendjes af te spreken, te sporten of
                            naar de naschoolse opvang te gaan.
                        </li>
                    </ul>
                </p>
            </section>
            <section className='section'>
                <h2 className='section-title'>Vakantieplanning {now.clone().subtract(1,
                    'year').year()} – {now.year()}</h2>
                <div className='holiday-items-container'>
                    {loading ? renderDummyItems() : holidayItems.map(renderItem)}
                </div>
                De studie- en lesvrije dagen staan vermeld in onze Bewaarvlucht en worden elke keer in onze nieuwsbrief
                de Vogelvlucht vermeld.
            </section>
        </div>
    );
}

OpeningHoursPage.displayName = 'OpeningHoursPage';