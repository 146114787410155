import './style/footer.scss';
import IDate from '@dvrd/idate';
import React from 'react';

export default function Footer() {
    function renderAddress() {
        return (
            <div className='list'>
                <label className='bold'>Basisschool de Vuurvogel</label>
                <label>Arthur van Schendellaan 53</label>
                <label>1422 LB Uithoorn</label>
            </div>
        );
    }

    function renderContact() {
        return (
            <div className='list'>
                <label>&nbsp;</label>
                <label>Tel. <a className='footer-link' href='tel:0297-521755'>0297-521 755</a></label>
                <a className='footer-link' href='mailto:info@devuurvogel-uithoorn.nl'>info@devuurvogel-uithoorn.nl</a>
            </div>
        );
    }

    function renderCopyRight() {
        const thisYear = IDate.now().year();
        return <label className='copyright'>© {thisYear} Basisschool de Vuurvogel | All Rights Reserved</label>;
    }

    return (
        <div className='page-footer'>
            <div className='row'>
                {renderAddress()}
                {renderContact()}
            </div>
            {renderCopyRight()}
        </div>
    );
}

Footer.displayName = 'Footer';