import './style/upButton.scss';

import {AwesomeIcon} from '@dvrd/dvr-controls';
import React, {useEffect, useRef, useState} from 'react';

export default function UpButton() {
    const [hasScroll, setHasScroll] = useState(false);
    const scrolling = useRef(false);
    const scrollPos = useRef(window.scrollY);

    function onClickUp() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    function testScroll() {
        scrollPos.current = window.scrollY;
        if (!scrolling.current) {
            window.requestAnimationFrame(() => {
                setHasScroll(scrollPos.current > 200);
                scrolling.current = false;
            });
        }
        scrolling.current = true;
    }

    useEffect(() => {
        window.addEventListener('scroll', testScroll);
        return function () {
            window.removeEventListener('scroll', testScroll);
        };
    }, []);

    if (!hasScroll) return null;
    return (
        <div className='up-button' onClick={onClickUp}>
            <AwesomeIcon name='chevron-up' className='chev-icon'/>
            <span className='hover-text'>Omhoog</span>
        </div>
    );
}

UpButton.displayName = 'UpButton';