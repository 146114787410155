import {ResponseData} from '@dvrd/fetch';
import NavBlock from '../models/navBlockModel';
import {ABOUT_ROUTE, NEWSLETTER_ROUTE, REGISTER_ROUTE, TEAM_ROUTE, VACANCIES_ROUTE} from './constants';
import {captureException} from './utils';
import {dispatchCustomEvent, getSetting} from '@dvrd/dvr-controls';

let navBlocks: Array<NavBlock> = [
    new NavBlock({label: 'Onze school', url: ABOUT_ROUTE}),
    new NavBlock({label: 'Het team', url: TEAM_ROUTE}),
    new NavBlock({
        label: 'Nieuwsbrieven', image: getSetting('staticURL') + '/cropped-favicon-270x270.png', url: NEWSLETTER_ROUTE
    }),
    new NavBlock(
        {label: 'Werken bij', image: getSetting('staticURL') + '/cropped-favicon-270x270.png', url: VACANCIES_ROUTE}),
    new NavBlock({
        label: 'Aanmelden', url: REGISTER_ROUTE,
        image: getSetting('staticURL') + '/cropped-favicon-270x270.png'
    })
];

export function getNavBlocks() {
    // TODO
    return navBlocks;
}

function loadNavBlocks() {
    NavBlock.getAll({active: true}, (blocks: Array<NavBlock>, success: boolean, data: ResponseData) => {
        if (!success) return captureException(new Error(data.message));
        navBlocks = blocks;
        navBlocks.sort((blockA: NavBlock, blockB: NavBlock) => blockA.index - blockB.index);
        dispatchCustomEvent('navBlocksLoaded');
    });
}

export const CoreItems: Array<[string, string]> = [
    ['Aandacht',
        'Wij zijn oprecht geïnteresseerd in elk kind. Nieuwsgierig naar hoe het met hem of haar gaat. Wat goed gaat, maar ook wat lastig is. We zien en horen elke leerling die aan ons is toevertrouwd. Daarnaast hebben we ook als onderwijsteam aandacht voor elkaar en steunen elkaar waar nodig.'],
    ['Bevlogen',
        'Onderwijs zit in ons hart. Onze bevlogenheid bestaat eruit dat we de ons toevertrouwde kinderen willen ontwikkelen. Dit doen we door ons zelf open te stellen voor nieuwe onderwijsvormen die bijdragen aan het doel om elk kind te laten slagen. We zijn nieuwsgierig naar de drijfveren, interesses en kwaliteiten van onze leerlingen, maar ook van elkaar.'],
    ['Eigenheid',
        'Onze leerlingen en medewerkers zijn het sterkst als ze de ruimte krijgen om zichzelf te zijn. Om deze individuele eigenheid tot zijn recht te laten komen wijken we -als dat nodig is- af van de gebaande paden. Dit met als doel om ieders unieke kwaliteiten zo goed mogelijk tot zijn recht te laten komen.'],
    ['Vertrouwen',
        'Dit vormt het fundament onder elke relatie. Daarom is dat voor ons een belangrijke kernwaarde. Vertrouwen hebben in onze leerlingen dat ze de verwachtingen waarmaken. Vertrouwen binnen het onderwijsteam dat we ons met elkaar inzetten om ‘elk kind een winnaar te laten zijn’. Hiermee halen we het beste in elkaar naar boven.']
];