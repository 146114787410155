import React from 'react';

export default function MrPage() {
    return (
        <div className='default-page mr-page'>
            <h1 className='page-title'>Medezeggenschapsraad (MR)</h1>
            <section className='section'>
                <h2 className='section-title'>De leden van de MR stellen zich graag aan u voor</h2>
                <div className='yt-container'>
                    <iframe width='560' height='315'
                            src='https://www.youtube.com/embed/SYHde31XOMQ?si=c2hZeZN19a1E5XYD&rel=0'
                            title='YouTube video player' frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin' allowFullScreen/>
                </div>
            </section>
            <section className='section'>
                <h2 className='section-title'>Wat doet de MR?</h2>
                <p className='section-par'>
                    Via de Medezeggenschapsraad (MR) hebben zowel het personeel als de ouders hun wettelijk
                    voorgeschreven invloed op tal van aangelegenheden die de school aangaan. De MR is een onafhankelijk
                    en zelfstandig orgaan. Dit betekent in de praktijk dat er door de directie en het bestuur van de
                    Stichting Wijzer aan de Amstel geen beleid mag worden gemaakt zonder dat dit voor advies of
                    instemming is voorgelegd aan de MR.<br/><br/>
                    <label className='par-title'>Vragen aan de MR?</label>
                    Als ouder kunt u bij de MR terecht met vragen en opmerkingen over beleidsmatige zaken. De MR gaat
                    niet over individuele situaties, zoals vragen over de leerprestaties van uw kind of problemen met de
                    leerkracht. Hiervoor kunt u bij de leerkracht of de directie terecht.<br/><br/>
                    <label className='par-title'>Terugkoppeling</label>
                    De MR doet regelmatig verslag van de besproken gespreksonderwerpen in onze nieuwsbrief
                    ‘Vogelvlucht’. Ook kunt u op het mededelingenbord in de hal zien wat de School MR belangrijk
                    vindt.<br/><br/>
                    Heeft u vragen en/of opmerkingen voor de MR, stuur dan een mail
                    naar <a className='default-link' href='mailto:mr@devuurvogel-uithoorn.nl'
                            target='_blank'>mr@devuurvogel-uithoorn.nl</a>
                </p>
            </section>
        </div>
    );
}

MrPage.displayName = 'MrPage';