import React, {useEffect, useState} from 'react';
import Employee from '../../../models/employeeModel';
import {REQUEST_WAIT_TIME, USE_DUMMY_DATA} from '../../../util/testUtil';
import TeamPage from './teamPage';

const testEmployees: Array<Employee> = [
    new Employee(
        {firstname: 'Dirk', lastname: 'Gerrits', group: 'Directeur', email: 'dirk@devuurvogel-uithoorn.nl'}),
    new Employee(
        {firstname: 'Sascha', lastname: 'van Rijn', group: 'Leerkracht', email: 'sascha@devuurvogel-uithoorn.nl'}),
    new Employee({firstname: 'Davey', lastname: 'van Rijn', group: 'Developer', email: 'info@davevanrijn.nl'})
];

export default function TeamController() {
    const [team, setTeam] = useState<Array<Employee>>([]);
    const [loading, setLoading] = useState(true);

    function loadTeam() {
        if (USE_DUMMY_DATA)
            window.setTimeout(() => {
                setTeam(testEmployees);
                setLoading(false);
            }, REQUEST_WAIT_TIME);
        else
            Employee.getAll({active: true}, (employees: Array<Employee>, success: boolean) => {
                setLoading(false);
                if (success) setTeam(employees);
            });
    }

    useEffect(() => {
        loadTeam();
    }, []);

    return (
        <TeamPage team={team} loading={loading}/>
    );
}

TeamController.displayName = 'TeamController';