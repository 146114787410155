import classNames from 'classnames';
import React, {MouseEventHandler} from 'react';
import {NavLink} from 'react-router-dom';

interface Props {
    onClick?: MouseEventHandler;
    route?: string;
    className?: string;
}

const fill1 = 'url(#linear-gradient)';
const fill2 = 'url(#linear-gradient-2)';
const fill3 = 'url(#linear-gradient-3)';
const fill4 = 'url(#linear-gradient-4)';
const fill5 = 'url(#linear-gradient-5)';
const fill6 = 'url(#linear-gradient-6)';
const fill7 = '#e84910';
const fill8 = '#e5550f';
const fill9 = '#fff';

export default function Logo(props: Props) {
    const {className, onClick, route} = props;

    function renderSVG() {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 585.8 323.73'
                 className={classNames('vuurvogel-logo', className)} onClick={onClick}>
                <defs>
                    <linearGradient id='linear-gradient' y1='188.93' x2='223.22' y2='188.93'
                                    gradientUnits='userSpaceOnUse'>
                        <stop offset='0.58' stopColor='#f08100'/>
                        <stop offset='1' stopColor='#e40f24'/>
                    </linearGradient>
                    <linearGradient id='linear-gradient-2' x1='228.96' y1='140.96' x2='391.33' y2='140.96'
                                    gradientUnits='userSpaceOnUse'>
                        <stop offset='0.07' stopColor='#e40f24'/>
                        <stop offset='0.62' stopColor='#f08100'/>
                    </linearGradient>
                    <linearGradient id='linear-gradient-3' x1='105.7' y1='8.36' x2='142.94' y2='8.36'
                                    gradientUnits='userSpaceOnUse'>
                        <stop offset='0.19' stopColor='#f08100'/>
                        <stop offset='0.84' stopColor='#e40f24'/>
                    </linearGradient>
                    <linearGradient id='linear-gradient-4' x1='87.73' y1='23.27' x2='132.66' y2='23.27'
                                    xlinkHref='#linear-gradient-3'/>
                    <linearGradient id='linear-gradient-5' x1='97.3' y1='42.76' x2='123.25' y2='42.76'
                                    xlinkHref='#linear-gradient-3'/>
                    <linearGradient id='linear-gradient-6' x1='139.92' y1='91.4' x2='227.11' y2='91.4'
                                    gradientUnits='userSpaceOnUse'>
                        <stop offset='0.19' stopColor='#f39200'/>
                        <stop offset='0.77' stopColor='#e40f24'/>
                    </linearGradient>
                </defs>
                <g id='Laag_2' data-name='Laag 2'>
                    <g id='Layer_1' data-name='Layer 1'>
                        <path fill={fill1}
                              d='M0,89.5S208.29,70.17,208.29,303.62C208.29,303.62,306.31,4.32,0,89.5Z'/>
                        <path fill={fill2}
                              d='M391.33,94.58s-126.46-41.92-162,119.19C229.29,213.77,214-6.24,391.33,94.58Z'/>
                        <path fill={fill3} d='M142.94,16.71S111.66-4.73,106.19,1,142.94,16.71,142.94,16.71Z'/>
                        <path fill={fill4} d='M132.66,32.9s-35.44-23.84-44-18.59S131.57,34.22,132.66,32.9Z'/>
                        <path fill={fill5} d='M123.25,45.81S101.37,36,97.65,41,121.06,46.25,123.25,45.81Z'/>
                        <path fill={fill6}
                              d='M192.32,72.58s-6.79-9.19-1.76-9.19,24.07-4.82,24.07-9.19S193,60.33,193,53.32,223,26.63,220.32,24,192.64,41,190.78,39.1s-9.19-11.16-18.59-16.84S139.92,24,139.92,41.4c0,6.52,4.48,25.71,25.68,30.44,30.73,21.67,56.94,53.88,61.14,90.59C226.74,162.43,233.17,113.43,192.32,72.58ZM165.81,46.69a6.46,6.46,0,1,1,6.45-6.46A6.46,6.46,0,0,1,165.81,46.69Z'/>
                        <path fill={fill7}
                              d='M134.38,158.88l-15,33.2a4.21,4.21,0,0,0-.46,1.8c0,1.31.77,2,2.41,2a13.84,13.84,0,0,0,1.34-.41c.62-.17,1-.12,1,.15a5.07,5.07,0,0,1-.72,3.19,6.54,6.54,0,0,1-6.33,3.92q-4,0-5.51-2.53c-1.65,1.68-4.24,2.53-7.77,2.53q-9.84,0-9.84-7.9,0-6.79,5.56-13.15,5.74-6.49,12.49-6.5,4.05,0,6,1.89l8.25-18.1Zm-20.49,24.41c0-1.93-.91-2.9-2.73-2.9q-3.19,0-6.43,5.89-2.88,5.22-2.88,8.67,0,3.15,2.67,3.15,2.88,0,6.28-6.14Q113.89,186.49,113.89,183.29Z'/>
                        <path fill={fill8}
                              d='M149,183.84a13.49,13.49,0,0,1-1.65,7.06H133.09a7.68,7.68,0,0,0-.72,3.39c0,2.1,1.22,3.14,3.66,3.14a17.6,17.6,0,0,0,9.78-3.24v4.89a19.1,19.1,0,0,1-11.79,3.5,10.33,10.33,0,0,1-7-2.21,8.17,8.17,0,0,1-2.83-6.64q0-6.22,5.35-12.31,5.57-6.27,11.74-6.28Q149,175.14,149,183.84Zm-6.59-1.39c0-1.85-.58-2.78-1.75-2.78s-2.2,1-3.5,2.89a21.33,21.33,0,0,0-2.32,4.37h6.64A12.52,12.52,0,0,0,142.41,182.45Z'/>
                        <path fill={fill7}
                              d='M315.24,157.67q-3.45,7.79-7,15.46-6.39,14.63-7,19.36c-.35,3.09.88,4.63,3.67,4.63.14,0,1-.25,2.72-.73,1.19-.35,1.81-.25,1.88.31q.31,1.61-1.15,5.31a14.87,14.87,0,0,1-4.66,6.27,11.28,11.28,0,0,1-6.85,3.07q-8.58.32-11.31-5-4.81,5-16.43,5-15.18,0-15.18-16.85,0-6.9,5.13-19.47,4.71-11.31,8.58-16.12,2.94-3.66,6.18-3.66,6.39,0,7.33.1,2.5.1,2.19,1.89-5.13,10.66-13,31.71a9.2,9.2,0,0,0-.41,2.62q0,5.55,4.92,5.55,5.75,0,11.72-10.63.84-1.47,9.73-20.72,4.41-9.36,6.91-10.1,3.26-.84,10.47-.42C315.2,155.32,315.73,156.12,315.24,157.67Z'/>
                        <path fill={fill7}
                              d='M373.54,157.67q-3.45,7.79-7,15.46-6.39,14.63-7,19.36t3.66,4.63c.14,0,1.05-.25,2.72-.73,1.19-.35,1.82-.25,1.89.31.2,1.07-.18,2.84-1.16,5.31a14.84,14.84,0,0,1-4.65,6.27,11.32,11.32,0,0,1-6.86,3.07q-8.58.32-11.31-5-4.81,5-16.43,5-15.18,0-15.18-16.85,0-6.9,5.13-19.47,4.71-11.31,8.59-16.12,2.93-3.66,6.17-3.66,6.39,0,7.33.1c1.67.07,2.41.7,2.2,1.89q-5.13,10.66-13,31.71a8.88,8.88,0,0,0-.42,2.62q0,5.55,4.92,5.55,5.76,0,11.72-10.63.84-1.47,9.74-20.72,4.39-9.36,6.91-10.1,3.24-.84,10.46-.42C373.51,155.32,374,156.12,373.54,157.67Z'/>
                        <path fill={fill7}
                              d='M433.1,165.09q-.42-.1-16.93,7.53c.06-1.6-.77-2.4-2.51-2.4q-10.05,0-20,17A122.23,122.23,0,0,0,387.83,199l-5.11,11.17-13.37.11c-1.6,0-2.58-.35-2.93-1.05s-.28-1.81.42-3.35l22.93-50.14h17.37L404,163.31q5.55-8,15.81-8Q432.26,155.35,433.1,165.09Z'/>
                        <path fill={fill7}
                              d='M388.51,227.32q0,2.52-5.55,6-11.52,7.23-20.73,24.81-4.5,8.47-12.77,30.25l-7.74-6.28q.31-21.15-6.7-36.85c-2.17-4.74-4.08-7.11-5.76-7.11q-3.45,0-7.22,9.52l-5-2.51q6.06-18.31,16-18.32,5.87,0,12.35,9.42a58.71,58.71,0,0,1,7.43,15,50.64,50.64,0,0,1,13.93-17.48q9.11-7.43,19.15-7.85C387.64,225.72,388.51,226.21,388.51,227.32Z'/>
                        <path fill={fill8}
                              d='M436.76,229l-5.65,6.15a5.83,5.83,0,0,0-1.15-.11,4.7,4.7,0,0,0-3,.94,16.1,16.1,0,0,1,1.47,6.7q0,15.28-11.67,27.64T389.87,282.7q-19.15,0-19.16-16.23,0-15.08,12-27.42t26.95-12.35q6.71,0,10.89,2.3a15.88,15.88,0,0,1,8.79-2.3A11.52,11.52,0,0,1,436.76,229Zm-23.55,13.26c0-3.9-1.88-5.86-5.65-5.86q-6,0-13.5,12.14-7,11.31-7,17.8,0,4.92,5.13,4.92,6.7,0,14.13-11.31Q413.22,249.52,413.21,242.29Z'/>
                        <path fill={fill8}
                              d='M494.86,229l-5.66,6.15a5.83,5.83,0,0,0-1.15-.11,4.67,4.67,0,0,0-3,.94,16.25,16.25,0,0,1,1.46,6.7q0,14.86-13.81,29.1Q462.3,282.7,448,282.7q-9.73,0-14.76-5.13-4.4,2.07-4.39,6.54,0,5.7,10.88,5.39h4.92q8.47,0,13.71,4.4,5.44,4.71,5.45,13,0,9.42-6.07,13.5-5,3.35-15.08,3.35-9.11,0-19.05-5.55-11.4-6.27-11.83-14.65l8.17-1.26q.94,5.34,8.79,8.59a31.91,31.91,0,0,0,12.77,2.72q6.81,0,6.81-4.19c0-2.79-1.68-4.47-5-5q-.74-.11-8.16-.11-17.91,0-17.9-15,0-5,4-9.94a23.57,23.57,0,0,1,8.69-6.6,17.33,17.33,0,0,1-1-6.28q0-15.08,12-27.42t27-12.35q6.69,0,10.88,2.3a15.88,15.88,0,0,1,8.79-2.3A11.57,11.57,0,0,1,494.86,229ZM471.3,242.29c0-3.9-1.88-5.86-5.65-5.86q-6,0-13.5,12.14-7,11.31-7,17.8,0,4.92,5.23,4.92,6.6,0,14-11.2Q471.3,249.52,471.3,242.29Z'/>
                        <path fill={fill8}
                              d='M537,244.6q0,8.79-3.35,14.34h-29a15.76,15.76,0,0,0-1.46,6.91q0,6.38,7.43,6.38,9.94,0,19.89-6.59v9.94q-9.52,7.13-24,7.12-8.9,0-14.13-4.5-5.76-4.81-5.76-13.51,0-12.66,10.89-25,11.3-12.78,23.86-12.77Q537,226.91,537,244.6Zm-13.4-2.83q0-5.66-3.56-5.65-3.14,0-7.12,5.86a44.4,44.4,0,0,0-4.71,8.9h13.51A25.76,25.76,0,0,0,523.64,241.77Z'/>
                        <path fill={fill8}
                              d='M585.8,193.93l-30,65.62a17.83,17.83,0,0,0-1.63,6.29c0,2.17,1.23,3.25,3.72,3.25a22.33,22.33,0,0,0,2.79-.73c1.17-.35,1.79-.25,1.86.31q.42,2.2-1.15,6.07a12.71,12.71,0,0,1-4.82,5.34,14.05,14.05,0,0,1-8.27,2.83q-12.76.1-12.87-12.67,0-5.13,6.07-18.42L568.23,194Z'/>
                        <path fill={fill9}
                              d='M113.63,104.46l6.81-16.17,6.06,2.56a8.16,8.16,0,0,1,2.77,1.74,4.16,4.16,0,0,1,1.12,2.25,4.33,4.33,0,0,1-.27,2.4A4,4,0,0,1,128.74,99a4.33,4.33,0,0,1-2.32.76,4.68,4.68,0,0,1,1.64,2.42,4.27,4.27,0,0,1-.25,2.78,5.06,5.06,0,0,1-1.36,1.91,4.5,4.5,0,0,1-1.82,1,5.46,5.46,0,0,1-2.11,0,11.38,11.38,0,0,1-2.72-.83Zm3-1,4,1.7a9.28,9.28,0,0,0,1.49.53,3.69,3.69,0,0,0,1.42.08,2.53,2.53,0,0,0,1.19-.55,3.17,3.17,0,0,0,.9-1.23,3,3,0,0,0,.2-1.77,2.73,2.73,0,0,0-.89-1.48,8.78,8.78,0,0,0-2.26-1.26l-3.73-1.58ZM119.72,96l3.49,1.47a8.56,8.56,0,0,0,2.12.67,2.43,2.43,0,0,0,1.57-.29,2.54,2.54,0,0,0,1-1.24,2.74,2.74,0,0,0,.21-1.58,2.17,2.17,0,0,0-.75-1.3A10,10,0,0,0,125,92.46l-3.24-1.36Z'/>
                        <path fill={fill9}
                              d='M136.48,113.54a8.08,8.08,0,0,1-2.71.16,6.89,6.89,0,0,1-2.29-.77,4.64,4.64,0,0,1-2.33-2.45,3.37,3.37,0,0,1,.27-2.83,3.45,3.45,0,0,1,1.19-1.29,3.41,3.41,0,0,1,1.57-.55,4.94,4.94,0,0,1,1.7.12,16.23,16.23,0,0,1,1.83.63,19,19,0,0,0,3.73,1.19l.28-.48a2.36,2.36,0,0,0,.35-1.92,3.62,3.62,0,0,0-1.8-1.82,3.78,3.78,0,0,0-2.23-.61,3.27,3.27,0,0,0-1.84,1.14l-1.71-1.26a5.15,5.15,0,0,1,1.88-1.46,4.05,4.05,0,0,1,2.28-.21,8.27,8.27,0,0,1,2.75,1,7.55,7.55,0,0,1,2.13,1.6,3.38,3.38,0,0,1,.85,1.56,3.34,3.34,0,0,1-.11,1.61,11.64,11.64,0,0,1-.86,1.79L140,111.2a31.86,31.86,0,0,0-1.7,3.4,4.78,4.78,0,0,0-.25,1.6l-2-1.08A4.4,4.4,0,0,1,136.48,113.54Zm2.15-4.3a15.85,15.85,0,0,1-3.48-1,8.59,8.59,0,0,0-1.85-.52,1.83,1.83,0,0,0-1.09.21,1.81,1.81,0,0,0-.75.75,1.84,1.84,0,0,0-.11,1.57,2.75,2.75,0,0,0,1.39,1.42,4.55,4.55,0,0,0,2.21.59,3.34,3.34,0,0,0,2-.63,5.17,5.17,0,0,0,1.34-1.72Z'/>
                        <path fill={fill9}
                              d='M141.72,114l1.95.91a3.08,3.08,0,0,0-.27,2.19,3.53,3.53,0,0,0,1.52,1.85,3.42,3.42,0,0,0,2.17.73,1.8,1.8,0,0,0,1.38-.78,1.28,1.28,0,0,0,.12-1.31,9.52,9.52,0,0,0-1.42-1.83,20.11,20.11,0,0,1-2.14-2.7,3.15,3.15,0,0,1-.47-1.77,3.1,3.1,0,0,1,.56-1.68,3.22,3.22,0,0,1,1.2-1.09,3.47,3.47,0,0,1,1.55-.4,4.41,4.41,0,0,1,1.49.24,6.56,6.56,0,0,1,1.68.83A7.43,7.43,0,0,1,153,111a3.6,3.6,0,0,1,.73,1.91,4.58,4.58,0,0,1-.49,2l-1.9-.94a2.35,2.35,0,0,0,.17-1.73,3.07,3.07,0,0,0-1.31-1.55,3.47,3.47,0,0,0-2-.77,1.44,1.44,0,0,0-1.17.59,1.25,1.25,0,0,0-.2.78,2,2,0,0,0,.36.93c.14.19.56.7,1.27,1.52a25.88,25.88,0,0,1,2.09,2.62,3.26,3.26,0,0,1,.54,1.73,3.13,3.13,0,0,1-.6,1.81,3.64,3.64,0,0,1-1.66,1.33,4.09,4.09,0,0,1-2.32.21,6.71,6.71,0,0,1-2.52-1.07,5.92,5.92,0,0,1-2.55-3A4.79,4.79,0,0,1,141.72,114Z'/>
                        <path fill={fill9}
                              d='M150.36,124.67l7.8-10,1.7,1.32-7.8,10Zm9.25-11.89,1.52-2,1.7,1.32-1.52,2Z'/>
                        <path fill={fill9}
                              d='M156.55,124.61l1.81,1.17a2.92,2.92,0,0,0-.56,2.12,3.44,3.44,0,0,0,1.25,2,3.39,3.39,0,0,0,2,1,1.78,1.78,0,0,0,1.48-.58,1.3,1.3,0,0,0,.3-1.28,9.17,9.17,0,0,0-1.16-2,20.08,20.08,0,0,1-1.75-3,3.19,3.19,0,0,1-.23-1.83,3.23,3.23,0,0,1,.78-1.58,3.29,3.29,0,0,1,1.34-.92,3.6,3.6,0,0,1,1.59-.19,4.35,4.35,0,0,1,1.44.45,6.48,6.48,0,0,1,1.56,1,7.28,7.28,0,0,1,1.67,2.07,3.59,3.59,0,0,1,.47,2,4.61,4.61,0,0,1-.76,2l-1.76-1.19a2.29,2.29,0,0,0,.4-1.69,3.09,3.09,0,0,0-1.08-1.71,3.57,3.57,0,0,0-1.91-1,1.45,1.45,0,0,0-1.24.44,1.17,1.17,0,0,0-.3.74,1.8,1.8,0,0,0,.23,1q.17.32,1.05,1.68a28.6,28.6,0,0,1,1.71,2.87,3.34,3.34,0,0,1,.3,1.79,3.19,3.19,0,0,1-.84,1.72,3.64,3.64,0,0,1-1.83,1.09,4.06,4.06,0,0,1-2.33-.11,6.66,6.66,0,0,1-2.34-1.4,6,6,0,0,1-2.13-3.29A4.76,4.76,0,0,1,156.55,124.61Z'/>
                        <path fill={fill9}
                              d='M165.9,133.09l1.68,1.34a3,3,0,0,0-.77,2.06,3.43,3.43,0,0,0,1.05,2.15,3.34,3.34,0,0,0,1.93,1.22,1.8,1.8,0,0,0,1.53-.43,1.3,1.3,0,0,0,.42-1.24,9.53,9.53,0,0,0-1-2.12,19.48,19.48,0,0,1-1.46-3.12,3.12,3.12,0,0,1,.89-3.34,3.37,3.37,0,0,1,1.42-.78,3.64,3.64,0,0,1,1.61,0,4.45,4.45,0,0,1,1.38.59,6.7,6.7,0,0,1,1.45,1.19,7.36,7.36,0,0,1,1.46,2.23,3.55,3.55,0,0,1,.27,2,4.64,4.64,0,0,1-.95,1.88l-1.64-1.36a2.29,2.29,0,0,0,.57-1.65,3,3,0,0,0-.91-1.81,3.53,3.53,0,0,0-1.79-1.22,1.43,1.43,0,0,0-1.28.3,1.2,1.2,0,0,0-.37.71,1.8,1.8,0,0,0,.13,1c.09.22.38.81.88,1.78a27.43,27.43,0,0,1,1.42,3,3.23,3.23,0,0,1,.12,1.81,3.16,3.16,0,0,1-1,1.63,3.66,3.66,0,0,1-1.92.91,4.13,4.13,0,0,1-2.31-.35,6.49,6.49,0,0,1-2.19-1.62,6,6,0,0,1-1.79-3.48A4.74,4.74,0,0,1,165.9,133.09Z'/>
                        <path fill={fill9}
                              d='M180.54,149.3l1.05,1.86a5.49,5.49,0,0,1-3.81.63,5.18,5.18,0,0,1-3.09-2.07,5.51,5.51,0,0,1-1.15-4.38,6.93,6.93,0,0,1,3-4.2,8.85,8.85,0,0,1,3.3-1.61,4.73,4.73,0,0,1,3.09.28,6.2,6.2,0,0,1,2.44,2,5.25,5.25,0,0,1,1.18,3.34,4.79,4.79,0,0,1-1.37,3.13l-1.51-1.49a3.47,3.47,0,0,0,.91-2,2.77,2.77,0,0,0-.58-1.86,3.38,3.38,0,0,0-2.52-1.42,5.59,5.59,0,0,0-3.57,1.4,5.74,5.74,0,0,0-2.39,3,3.2,3.2,0,0,0,.6,2.75,3.09,3.09,0,0,0,1.89,1.23A3.88,3.88,0,0,0,180.54,149.3Z'/>
                        <path fill={fill9}
                              d='M179.07,155.33l15.08-9,1.1,1.85-5.41,3.22a4.79,4.79,0,0,1,3.44,2.37,5.1,5.1,0,0,1,.78,2.4,3.23,3.23,0,0,1-.56,2.06,7,7,0,0,1-2.22,1.84l-6.92,4.12-1.1-1.85,6.92-4.12a3.29,3.29,0,0,0,1.66-1.8,2.56,2.56,0,0,0-.38-2.08,3.4,3.4,0,0,0-1.34-1.29,2.81,2.81,0,0,0-1.77-.35,6.05,6.05,0,0,0-2.2.89l-6,3.55Z'/>
                        <path fill={fill9}
                              d='M191.33,163.77a6.54,6.54,0,0,1,5.57-.39,5.86,5.86,0,0,1,2.94,3,5.69,5.69,0,0,1,.22,4.61,6.47,6.47,0,0,1-3.62,3.49,8.48,8.48,0,0,1-3.81.94,5.29,5.29,0,0,1-2.88-1,6.25,6.25,0,0,1-2-2.47,5.71,5.71,0,0,1-.24-4.63A6.78,6.78,0,0,1,191.33,163.77Zm.92,2a5.43,5.43,0,0,0-2.88,2.49,3.55,3.55,0,0,0,2.23,4.86,5.53,5.53,0,0,0,3.82-.58,5.24,5.24,0,0,0,2.81-2.46,3.56,3.56,0,0,0-2.22-4.86A5.37,5.37,0,0,0,192.25,165.78Z'/>
                        <path fill={fill9}
                              d='M197.16,176.81c2.22-.76,4.09-.71,5.58.17a6,6,0,0,1,2.63,3.32,5.7,5.7,0,0,1-.25,4.61,6.51,6.51,0,0,1-4,3.11,8.54,8.54,0,0,1-3.89.55,5.26,5.26,0,0,1-2.76-1.33,6.17,6.17,0,0,1-1.72-2.65A5.73,5.73,0,0,1,193,180,6.87,6.87,0,0,1,197.16,176.81Zm.71,2.09a5.41,5.41,0,0,0-3.11,2.19,3.54,3.54,0,0,0,1.73,5.06,5.56,5.56,0,0,0,3.86-.19,5.27,5.27,0,0,0,3-2.17,3.55,3.55,0,0,0-1.72-5.06A5.43,5.43,0,0,0,197.87,178.9Z'/>
                        <path fill={fill9} d='M195.55,192.52,212.49,188l.56,2.08-16.94,4.56Z'/>
                    </g>
                </g>
            </svg>
        );
    }

    if (route) return <NavLink to={route}>{renderSVG()}</NavLink>;
    return renderSVG();
}

Logo.displayName = 'Logo';