import './style/contactPage.scss';

import {ChangeFunction, DvrdButton, DvrdInput, Loader, preventDefault} from '@dvrd/dvr-controls';
import React, {FocusEventHandler, MouseEventHandler} from 'react';
import {ContactErrors} from "../../../util/interfaces";

interface Props {
    onChangeName: ChangeFunction<string>;
    onChangeEmail: ChangeFunction<string>;
    onChangeSubject: ChangeFunction<string>;
    onChangeMessage: ChangeFunction<string>;
    onResetError: (key: keyof ContactErrors) => FocusEventHandler;
    onSubmit: MouseEventHandler;
    name: string;
    email: string;
    subject: string;
    message: string;
    errors: ContactErrors;
    loading: boolean;
}

export default function ContactPage(props: Props) {
    const {
        onChangeName, onChangeEmail, onChangeSubject, onChangeMessage, onResetError, onSubmit, name, email, subject,
        message, errors, loading
    } = props;

    function renderForm() {
        return (
            <form className='contact-form' onSubmit={preventDefault}>
                <Loader active={loading}/>
                <DvrdInput value={name} onChange={onChangeName} label='Naam' placeholder='Naam' error={errors.name}
                           onFocus={onResetError('name')}/>
                <DvrdInput value={email} onChange={onChangeEmail} label='E-mailadres' placeholder='E-mail'
                           error={errors.email} onFocus={onResetError('email')}/>
                <DvrdInput value={subject} onChange={onChangeSubject} label='Onderwerp' placeholder='Onderwerp'
                           error={errors.subject} onFocus={onResetError('subject')}/>
                <DvrdInput value={message} onChange={onChangeMessage} label='Bericht' placeholder='Bericht'
                           error={errors.message} onFocus={onResetError('message')} inputProps={{rows: 10}} area/>
                <DvrdButton onClick={onSubmit} label='Verzenden' className='btn-submit'/>
            </form>
        )
    }

    return (
        <main className='default-page contact-page'>
            <h1 className='page-title'>Contact</h1>
            <section className='section'>
                <h2 className='section-title'>De Vuurvogel</h2>
                <p className='section-par'>
                    Basisschool De Vuurvogel<br/>
                    Arthur van Schendellaan 53<br/>
                    1422 LB Uithoorn<br/>
                    <a href='tel:0297-521 755' className='default-link' target='_blank'>0297-521 755</a><br/>
                    <a href='mailto:info@devuurvogel-uithoorn.nl' className='default-link'
                       target='_blank'>info@devuurvogel-uithoorn.nl</a>
                </p>
                <div className='maps-container'>
                    <iframe loading="lazy" style={{border: 0}} tabIndex={0}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2442.6927352958087!2d4.828885616046892!3d52.2489644797638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5dfee5695b333%3A0x30d0e17cb2a3c686!2sDe%20Vuurvogel!5e0!3m2!1snl!2snl!4v1591272640184!5m2!1snl!2snl"
                            width="100%" height="800px" frameBorder={0} allowFullScreen aria-hidden={false}/>
                </div>
            </section>
            <section className='section'>
                <h2 className='section-title'>Contactformulier</h2>
                {renderForm()}
            </section>
        </main>
    )
}