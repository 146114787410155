import React from 'react';

export default function RegisterPage() {
    return (
        <div className='default-page register-page'>
            <h1 className='page-title'>Aanmelden</h1>
            <section className='section'>
                <h2 className='section-title'>Aanmelden</h2>
                <p className='section-par'>
                    Wilt u als ouder nader kennismaken met onze school? Dat kan natuurlijk! Wij laten u dan onze school
                    zien en vertellen u hoe wij bij De Vuurvogel dagelijks invulling geven aan ons motto ‘Elk kind is
                    een winnaar’. We horen natuurlijk ook graag wat uw wensen zijn bij een school waar uw kind zich
                    thuis voelt.
                </p>
            </section>
            <section className='section'>
                <p className='section-par'>
                    <label className='par-title'>Afspraak maken?</label>
                    Om een afspraak te maken voor een vrijblijvende kennismaking, kunt u contact opnemen via mail <a
                    className='default-link' target='_blank'
                    href='mailto:info@devuurvogel-uithoorn.nl'>info@devuurvogel-uithoorn.nl</a> of telefoon <a
                    className='default-link' href='tel:0297-521755' target='_blank'>0297-521 755</a>.<br/><br/>
                    Tijdens de kennismaking ontvangt u een aanmeldformulier. Als u na de kennismaking besluit om uw kind
                    aan te melden, dan kunt u het aanmeldformulier ingevuld aan ons retourneren. Na een aantal dagen
                    ontvangt u van ons een bevestiging van de aanmelding. Een paar weken voordat uw kind 4 jaar wordt,
                    zal de groepsleerkracht contact met u opnemen voor een kort kennismakingsgesprek met u en uw
                    zoon/dochter. Tijdens dit gesprek worden diverse praktische zaken besproken, waaronder de datum van
                    de eerste schooldag. De ouders en het kind kunnen dan ook alvast een kijkje nemen in de groep en er
                    worden 1 of 2 wendagen ingepland.
                </p>
            </section>
        </div>
    );
}

RegisterPage.displayName = 'RegisterPage';