export const HOME_PATH = 'home';
export const HOME_ROUTE = `/${HOME_PATH}`;
export const ABOUT_PATH = 'over-ons';
export const ABOUT_ROUTE = `/${ABOUT_PATH}`;
export const TEAM_PATH = 'team';
export const TEAM_ROUTE = `/${TEAM_PATH}`;
export const QUALITY_PATH = 'kwaliteits-niveau';
export const QUALITY_ROUTE = `/${QUALITY_PATH}`;
export const AMBITION_PATH = 'onze-ambities';
export const AMBITION_ROUTE = `/${AMBITION_PATH}`;
export const AMSTEL_PATH = 'wijzer-aan-de-amstel';
export const AMSTEL_ROUTE = `/${AMSTEL_PATH}`;
export const REGISTER_PATH = 'aanmelden';
export const REGISTER_ROUTE = `/${REGISTER_PATH}`;
export const NEWSLETTER_PATH = 'nieuwsbrieven';
export const NEWSLETTER_ROUTE = `/${NEWSLETTER_PATH}`;
export const AGENDA_PATH = 'agenda';
export const AGENDA_ROUTE = `/${AGENDA_PATH}`;
export const OPENING_HOURS_PATH = 'openingstijden';
export const OPENING_HOURS_ROUTE = `/${OPENING_HOURS_PATH}`;
export const OV_PATH = 'oudervereniging';
export const OV_ROUTE = `/${OV_PATH}`;
export const MR_PATH = 'medezeggenschapsraad';
export const MR_ROUTE = `/${MR_PATH}`;
export const GMR_PATH = 'gemeenschappelijke-medezeggenschapsraad-gmr';
export const GMR_ROUTE = `/${GMR_PATH}`;
export const PARENTS_ROOM_PATH = 'ouderkamer';
export const PARENTS_ROOM_ROUTE = `/${PARENTS_ROOM_PATH}`;
export const THINK_TANK_PATH = 'leerlingendenktank';
export const THINK_TANK_ROUTE = `/${THINK_TANK_PATH}`;
export const DOCUMENTS_PATH = 'documenten';
export const DOCUMENTS_ROUTE = `/${DOCUMENTS_PATH}`;
export const VACANCIES_PATH = 'werken-bij';
export const VACANCIES_ROUTE = `/${VACANCIES_PATH}`;
export const CONTACT_PATH = 'contact';
export const CONTACT_ROUTE = `/${CONTACT_PATH}`;
export const LOGIN_ROUTE = '/inloggen';
export const DEVICE_ID_COOKIE_NAME = 'TODO-device-id';
export const AUTHENTICATED_ROUTE = HOME_ROUTE;

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DUTCH_DATE_FORMAT = 'DD-MM-YYYY';

export const ORANGE_VUURVOGEL = 'var(--orange-vuurvogel)';
export const COLOR_TEXT = 'var(--color-text)';