import './style/navBlocks.scss';
import {useEvent} from '@dvrd/dvr-controls';

import classNames from 'classnames';
import React, {MouseEventHandler, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import NavBlock from '../../models/navBlockModel';
import {getNavBlocks} from '../../util/siteData';

interface Props {
    onClickBlock: (block: NavBlock) => MouseEventHandler;
    block: NavBlock;
}

function Block(props: Props) {
    const {block, onClickBlock} = props;
    const [hovered, setHovered] = useState(false);

    function onMouseEnter() {
        setHovered(true);
    }

    function onMouseLeave() {
        setHovered(false);
    }

    function renderBlock() {
        const {backgroundColor, image, color, label, url} = block;
        let _backgroundColor = backgroundColor;
        if (hovered && !!url)
            _backgroundColor = `hsl(from ${backgroundColor} h s calc(l - 10))`;
        return (
            <div className={classNames('nav-block', !!image && 'with-image', !!url && 'with-url')}
                 style={{backgroundColor: _backgroundColor}} onMouseEnter={onMouseEnter}
                 onMouseLeave={onMouseLeave} onClick={onClickBlock(block)}>
                {renderImage(image)}
                <label style={{color}}>{label}</label>
            </div>
        );
    }

    function renderImage(image: string | null) {
        if (!image) return null;
        return <img src={image} className='nav-block-image' alt='de Vuurvogel'/>;
    }

    return renderBlock();
}

export default function NavBlocks() {
    const [navBlocks, setNavBlocks] = useState<Array<NavBlock>>(getNavBlocks());
    const navigate = useNavigate();
    useEvent('navBlocksLoaded', fetchNavBlocks);

    function onClickBlock(block: NavBlock) {
        return function () {
            const {url} = block;
            if (!url) return;
            if (url.startsWith('http')) window.open(url, '_blank');
            else navigate(url);
        };
    }

    function fetchNavBlocks() {
        setNavBlocks(getNavBlocks());
    }

    function renderBlock(block: NavBlock, index: number) {
        return <Block key={index} onClickBlock={onClickBlock} block={block}/>;
    }

    return (
        <div className='nav-blocks no-mob'>
            {navBlocks.map(renderBlock)}
        </div>
    );
}

NavBlocks.displayName = 'NavBlocks';