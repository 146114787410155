import React, {useEffect, useState} from 'react';
import CalendarItem from '../../../models/calendarItemModel';
import {CalendarItemType} from '../../../util/interfaces';
import {ResponseData} from '@dvrd/fetch';
import {REQUEST_WAIT_TIME, USE_DUMMY_DATA} from '../../../util/testUtil';
import AgendaPage from './agendaPage';

const dummyItems: Array<CalendarItem> = [
    new CalendarItem({from_date: '2024-01-24', label: 'Nationale voorleesdagen'}),
    new CalendarItem({from_date: '2024-01-25', label: 'Studiemiddag, lesvrij om 12.00 uur'}),
    new CalendarItem({from_date: '2024-02-06', to_date: '2024-02-07', label: 'Doorstroomtoets groep 8'}),
    new CalendarItem({from_date: '2024-02-15', label: 'Studiedag, lesvrij'}),
    new CalendarItem({from_date: '2024-02-19', to_date: '2024-02-23', label: 'Krokusvakantie'}),
    new CalendarItem({from_date: '2024-03-08', label: 'Rapport'}),
    new CalendarItem({from_date: '2024-03-12', to_date: '2024-03-15', label: 'Rapportgesprekken'})
];

export default function AgendaController() {
    const [items, setItems] = useState<Array<CalendarItem>>([]);
    const [loading, setLoading] = useState(true);

    function loadItems() {
        if (USE_DUMMY_DATA) {
            window.setTimeout(() => {
                setItems(dummyItems);
                setLoading(false);
            }, REQUEST_WAIT_TIME);
        } else {
            CalendarItem.getAll({active: true, item_type: CalendarItemType.GENERAL},
                (items: Array<CalendarItem>, success: boolean, data: ResponseData) => {
                    setLoading(false);
                    if (success) setItems(items);
                    // Report else
                });
        }
    }

    useEffect(() => {
        loadItems();
    }, []);

    return (
        <AgendaPage items={items} loading={loading}/>
    );
}

AgendaController.displayName = 'AgendaController';